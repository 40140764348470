import appsignal from '../../../appsignal';
import {
  Box,
  Text,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Flex,
  Button,
  Show,
  Hide,
  VStack,
  useToast,
  InputGroup,
  InputRightElement,
  useColorModeValue,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  REGEX_CONTAINS_BOTH_LOWER_AND_UPPERCASE,
  REGEX_HAS_AT_LEAST_ONE_NUMBER,
  REGEX_HAS_SYMBOL,
  lengthInBetween,
  hasLowerAndUpperCase,
  hasSymbol,
  hasDigit,
} from '../../../utils/validations';
import { CustomCheckIcon } from '../../../theme/icons/CustomCheckIcon';
import { CustomExclamationMarkIcon } from '../../../theme/icons/CustomExclamationMarkIcon';
import { useState } from 'react';
import CustomToast from '../../../common/CustomToast';
import { CustomVisibilityIcon } from '../../../theme/icons/CustomVisibilityIcon';
import { CustomVisibilityOffIcon } from '../../../theme/icons/CustomVisibilityOffIcon';
import { useContext } from 'react';
import { HttpContext } from '../../../context/HttpContext';

function FormChangePassword() {
  const toast = useToast();
  const { authAxios } = useContext(HttpContext);
  const [show, setShow] = useState(false);
  const modeBoxBg = useColorModeValue('gradient', 'secondaryDark');
  const modeTextBg = useColorModeValue('quaternaryBackground', 'primaryDark06');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const modeFormControlBg = useColorModeValue('white', 'primaryDark08');
  const modeLabelBg = useColorModeValue('#EFEFEF', 'primaryDark03');
  const modeIconColor = useColorModeValue('secondaryDark06', '#DDDDDD');

  const showPasswordClickHandler = () => setShow(!show);

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Please enter your old password'),
    passwordNew: Yup.string()
      .required('Please enter your new password')
      .min(6)
      .max(30)
      .matches(REGEX_CONTAINS_BOTH_LOWER_AND_UPPERCASE)
      .matches(REGEX_HAS_AT_LEAST_ONE_NUMBER)
      .matches(REGEX_HAS_SYMBOL),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
    watch,
    getFieldState,
    setError,
    reset,
  } = useForm(formOptions);

  const watchPassword = watch('passwordNew');
  const fieldStatePassword = getFieldState('passwordNew');

  const onSubmit = async (values) => {
    try {
      const submittedData = {
        oldPassword: values.oldPassword,
        plainPassword: values.passwordNew,
      };
      await authAxios.post(`accounts/me/password`, submittedData);
      toast({
        render: (props) => (
          <CustomToast
            status="success"
            title="Password changed."
            description="You have successfully changed your password."
            onClose={props.onClose}
            id={props.id}
          />
        ),
      });

      reset();
    } catch (onError) {
      appsignal.sendError(onError);
      console.log(onError);
      const errorMessage = onError.response.data.error;
      if (errorMessage) {
        setError('oldPassword', { type: 'server', message: errorMessage });
      }
    }
  };

  return (
    <Box className="autofillForDarkBg" w="full">
      <Box className="primaryBoxShadow" bg={modeBoxBg} borderRadius="15px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Text
            fontSize="18px"
            textStyle="headingFamilyMedium"
            p="14px 14px 14px 24px"
            bg={modeTextBg}
            borderRadius="15px 15px 0 0"
          >
            Change password
          </Text>
          <Divider borderColor={modeBorderColor} />

          <FormControl
            isRequired
            isInvalid={errors.oldPassword}
            bg={modeFormControlBg}
          >
            <Flex alignItems="center">
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FormLabel m={0}>Old password</FormLabel>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel>Old password</FormLabel>
                </Show>
                <InputGroup>
                  <Input
                    variant="filledForDarkBg"
                    id="oldPassword"
                    type={show ? 'text' : 'password'}
                    placeholder="Enter old password"
                    {...register('oldPassword')}
                  />
                  <InputRightElement>
                    {show ? (
                      <CustomVisibilityOffIcon
                        onClick={showPasswordClickHandler}
                        boxSize="18px"
                        cursor="pointer"
                        color={modeIconColor}
                      />
                    ) : (
                      <CustomVisibilityIcon
                        onClick={showPasswordClickHandler}
                        boxSize="18px"
                        cursor="pointer"
                        color={modeIconColor}
                      />
                    )}
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage pt={2} pl={3}>
                  {errors.oldPassword?.message || errors.oldPassword}
                </FormErrorMessage>
              </Box>
            </Flex>
          </FormControl>
          <Divider borderColor={modeBorderColor} />

          <FormControl
            isRequired
            isInvalid={errors.passwordNew}
            bg={modeFormControlBg}
          >
            <Flex alignItems="center">
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FormLabel m={0}>New password</FormLabel>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel>New password</FormLabel>
                </Show>
                <InputGroup>
                  <Input
                    variant="filledForDarkBg"
                    id="passwordNew"
                    type={show ? 'text' : 'password'}
                    placeholder="Enter new password"
                    {...register('passwordNew')}
                  />
                  <InputRightElement>
                    {show ? (
                      <CustomVisibilityOffIcon
                        onClick={showPasswordClickHandler}
                        boxSize="18px"
                        cursor="pointer"
                        color={modeIconColor}
                      />
                    ) : (
                      <CustomVisibilityIcon
                        onClick={showPasswordClickHandler}
                        boxSize="18px"
                        cursor="pointer"
                        color={modeIconColor}
                      />
                    )}
                  </InputRightElement>
                </InputGroup>
                {fieldStatePassword.invalid && (
                  <FormHelperText pt={2} pl={3}>
                    <VStack spacing={0} alignItems="left">
                      <Box color="error">Please enter your password:</Box>
                      <Box
                        color={
                          lengthInBetween(watchPassword, 6, 30)
                            ? 'success'
                            : 'error'
                        }
                      >
                        {lengthInBetween(watchPassword, 6, 30) ? (
                          <CustomCheckIcon boxSize={2} mr={1} />
                        ) : (
                          <CustomExclamationMarkIcon boxSize={2} mr={1} />
                        )}
                        contains at least 6 and maximum 30 characters
                      </Box>
                      <Box
                        color={
                          hasLowerAndUpperCase(watchPassword)
                            ? 'success'
                            : 'error'
                        }
                      >
                        {hasLowerAndUpperCase(watchPassword, 6, 30) ? (
                          <CustomCheckIcon boxSize={2} mr={1} />
                        ) : (
                          <CustomExclamationMarkIcon boxSize={2} mr={1} />
                        )}
                        contains both lower (a-z) and upper case letters (A-Z)
                      </Box>
                      <Box
                        color={
                          hasDigit(watchPassword) && hasSymbol(watchPassword)
                            ? 'success'
                            : 'error'
                        }
                      >
                        {hasDigit(watchPassword) && hasSymbol(watchPassword) ? (
                          <CustomCheckIcon boxSize={2} mr={1} />
                        ) : (
                          <CustomExclamationMarkIcon boxSize={2} mr={1} />
                        )}
                        contains at least one number (0-9) and a symbol
                      </Box>
                    </VStack>
                  </FormHelperText>
                )}
              </Box>
            </Flex>
          </FormControl>
          <Divider borderColor={modeBorderColor} />

          <Flex
            w="full"
            justifyContent="flex-end"
            bg={modeTextBg}
            borderRadius="0 0 15px 15px"
            p="20px 14px"
          >
            <Button
              type="submit"
              isLoading={isSubmitting}
              disabled={!isDirty || !isValid || isSubmitting}
              alt="Save new password"
              size="sm"
            >
              Save new password
            </Button>
          </Flex>
        </form>
      </Box>
    </Box>
  );
}

export default FormChangePassword;
